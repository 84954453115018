@import '/src/core/scss/all.scss';

.left-menu {
    display: flex;
    flex-direction: column;
    width: 250px;
    height: 100%;
    padding: 38px 10px 38px 48px;
    position: fixed;
    background: $white no-repeat;
    background-image: none;
    border-right: none;
    border-bottom: 1px solid $grey-lighter;

    .section-title {
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 13px;

        &.help {
            margin-top: 40px;

            a {
                color: $primary;
            }
        }

        &__icon {
            margin: 0 8px;
            padding-top: 2px;
        }

        h1 {
            font-size: $font-size-h1;
            font-weight: $font-weight-regular;
            color: var(--grey-darker);
        }
    }

    .links {
        ul {
            display: flex;
            flex-direction: column;
            gap: 3px;
        }

        li {
            list-style-type: none;
            padding: 9px 0 9px 10px;
            border-radius: $border-radius;

            .external-link {
                position: relative;
                width: 10px;
                margin-left: 6px;
            }

            a {
                color: $grey-dark;
            }

            &.help-list svg {
                margin-left: 6px;
            }

            &.active {
                background: $primary-light;
                color: $primary;
                cursor: pointer;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                &:after {
                    content: "";
                    height: 12px;
                    width: 25px;
                    background: url("/images/icons/arrow-right.svg") no-repeat;
                }

                a {
                    color: $primary;
                }

            }

            &:hover {
                color: $primary;
                cursor: pointer;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }
        }
    }
}
