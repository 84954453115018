@import 'metroplex/dist/base/styles/variables/_variables';
@import '/src/core/scss/all.scss';

//-----------------------------------------------
// Account menu
//-----------------------------------------------
.AccountMenu .Dropdown__block {
	overflow: hidden;
	width: auto;
}

.impersonate-profile__avatar {
	background-color: transparent;

	svg {
		height: 32px;
		width: 32px;
		fill: $grey-dark;
	}
}

.Dropdown.Dropdown--topbar {
	.caret {
		margin-left: var(--spacer-small);
		margin-top: var(--spacer-xsmall);

		.Icon {
			width: var(--spacer-medium);
			height: var(--spacer-medium);
		}
	}

	.Dropdown__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 12px 16px 0;
		color: var(--grey-darker);
		font-size: var(--size-medium);
		font-weight: var(--weight-semibold);

		p {
			margin: 0 0 4px;
		}
	}

	.Dropdown__body {
		padding: 4px 16px 12px;
		margin: 0;
		border-bottom: 1px solid #e0e0e0;

		.Dropdown--topbar__title {
			color: $grey-darker;
			font-size: $size-medium;
			font-weight: $weight-semibold;
		}

		&:last-child {
			border-bottom: 0;
		}
	}

	.Dropdown--topbar {
		border-bottom: 1px solid #e0e0e0;
		padding: 10px;

		&:last-child {
			border-bottom: 0;
		}

		ul li {
			display: flex;
			align-items: center;
		}

		.signOut {
			color: #DB524B
		}

		.menuItem {
			display: flex;
			align-items: center;
			white-space: nowrap;
			padding: 8px 10px;

			.Icon {
				width: 14px;
				height: 14px;
				margin-right: 8px;
				margin-top: -1px;
			}

			span {
				font-size: 14px;
				font-weight: 400;
				line-height: 20px;
				text-align: left;
			}

			&:hover {
				color: $primary;
				cursor: pointer;
				background: #ECF1FC;
				border-radius: 6px;
			}
		}
	}
}

@media screen and (max-width: $mobile) {
	.Dropdown.Dropdown--topbar {
		.Icon {
			margin-right: 4px;
		}

		.caret {
			margin-left: 4px;
		}
	}

	.AccountMenu .Dropdown__block {
		width: 300px;
		z-index: 1002;
		right: -25px;
		font-size: 10px;

		.Tree .TreeBranch .TreeBranch .TreeNode {
			padding-left: 12px !important;
		}

		.EstateLabel svg {
			height: 12px !important;
		}

		.ImpersonationButton svg {
			width: 70% !important;
		}
	}
}
