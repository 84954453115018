@import '/src/core/scss/all.scss';

.Topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: 64px;
    z-index: 10001;
    transition: top 0.5s ease;

    &--hidden {
        top: -130px;
    }

    &Section {
        flex: 1 0 auto;
        height: 40px;
        display: flex;
        align-items: center;

        &--left {
            padding-left: 14px;
        }

        &--center {
            flex: 3 0 auto;
            max-width: 600px;
            min-width: 300px;
            align-items: center;
            justify-content: center;
        }

        &--right {
            padding-right: 14px;
            justify-content: flex-end;
        }
    }

    .logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        align-items: baseline;

        img {
            height: 30px;
        }

        span {
            font-size: $font-size-logo;
            font-weight: $font-weight-bold;
            letter-spacing: -1px;
        }

        span.prefix {
            color: darken($white ,10%);
            margin-right: 3px;
        }

        span.product-name {
            color: $white;
            margin-right: 5px;
        }

        span.beta {
            position: relative;
            bottom: 2px;
            color: $white;
            font-size: $font-size-body;
            text-transform: uppercase;
            padding: 2px 6px 2px 4px;
            border-radius: $border-radius;
        }
    }

    &__dropdown--loading {
        height: 240px;
        display: flex;
        align-items: center;
    }
}

@media screen and (max-width: $tablet-small) {
    .Topbar {
        height: 64px;
    }
}

@media (max-width: $mobile) {
    .Topbar {
        height: 50px;

        .logo {
            span {
                font-size: 18px;
            }

            span.beta {
                font-size: 10px;
            }

            .AccountMenu {
                svg {
                    width: 12px !important;
                }

                .caret {
                    width: 8px !important;
                }
            }
        }
    }

    .dash-header {
        justify-content: flex-end;

        .dash-header-options-button {
            height: 40px !important;
            border-width: 1px 1px 1px 1px;
            border-radius: var(--gd-button-borderRadius, 3px);
        }
    }
}